<template>
  <v-app-bar app dense dark :color="mainColor">
    <v-toolbar-title class="d-flex flex-row" link="/home">
      <v-avatar height="32px" width="32px" min-width="32px">
        <img src="../assets/header-logo.png" />
      </v-avatar>
      <h1 class="title ml-1">{{ siteName }}</h1>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { SITE_NAME, MAIN_COLOR } from '@/constants'

export default {
  data() {
    return {
      siteName: SITE_NAME,
      mainColor: MAIN_COLOR
    }
  }
}
</script>
